import { RefObject, useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

/**
 * This function is used to detect click outside of specified element
 * @param ref - reference to element
 *
 * @example
 * const ref = useRef(null)
 * useOnClickOutside(ref, () => alert('hello'));
 *
 */

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: Handler
) => {
    useEffect(() => {
        const listener = (event: MouseEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
