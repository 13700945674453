import classNames from 'classnames';

import { CustomCheckboxProps } from '@tb-core/components/styled/form-controls/custom-checkbox';
import ThemableCheckbox from '@tb-core/components/styled/form-controls/themable-checkbox';

import styles from './styles.module.scss';

const BrandedCheckbox = ({
    children,
    className,
    ...props
}: CustomCheckboxProps) => (
    <ThemableCheckbox
        {...props}
        className={classNames(styles['branded-checkbox'], className)}
        theme="brand"
    >
        {children}
    </ThemableCheckbox>
);

export default BrandedCheckbox;
