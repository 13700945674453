import classnames from 'classnames';
import { ReactNode } from 'react';

import BrandedLoadingSpinner from '@tb-core/components/composites/branded-loading-spinner';
import Checkbox from '@tb-core/components/simple/form-controls/checkbox';
import Svg, { SvgProps } from '@tb-core/components/simple/svg';
import { InputProps, LabelProps } from '@tb-core/types/elements/form';

import styles from './styles.module.scss';
export interface CustomCheckboxProps {
    checkboxProps?: InputProps;
    children?: ReactNode;
    className?: string;
    isLoading?: boolean;
    labelProps?: LabelProps;
    svgProps: SvgProps;
}

const CustomCheckbox = ({
    checkboxProps,
    children,
    className,
    isLoading,
    labelProps,
    svgProps
}: CustomCheckboxProps) => (
    <label
        {...labelProps}
        className={classnames(styles['styled-checkbox'], className)}
    >
        {isLoading ? (
            <div className={styles['loading-spinner']}>
                <BrandedLoadingSpinner />
            </div>
        ) : (
            <>
                <Checkbox {...checkboxProps} />
                <Svg {...svgProps} />
            </>
        )}

        {children}
    </label>
);

export default CustomCheckbox;
