import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';

import StandardModal from '@tb-core/components/composites/standard-modal';
import { ModalProps } from '@tb-core/components/container/loyalty-modal';
import BrandedButton from '@tb-core/components/styled/buttons/branded-button';
import BrandedCheckbox from '@tb-core/components/styled/form-controls/branded-checkbox';
import { gtmLinkClick } from '@tb-core/helpers/analytics/google';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import postLoyaltyAcceptTerms from '@tb-core/providers/post-loyalty-activate-user';

import styles from './styles.module.scss';

const LoyaltyFirstTimeUserLegalModal = ({
    className,
    emailOptIn = true,
    hideClose,
    template = {}
}: ModalProps) => {
    const [optInChecked, setOptInChecked] = useState(false);
    const { updateModals } = useLayoutActivityContext();
    const { ctaTitle, emailOptInNotice, legal, subtitle, title } = template;

    const acceptTerms = (e: SyntheticEvent) => {
        e.preventDefault();

        gtmLinkClick({
            'Analytics-Action': 'Rewards > Sign Up',
            'Analytics-Value': `Existing Account > ${ctaTitle}`
        });

        postLoyaltyAcceptTerms(optInChecked).then(() => {
            updateModals({
                ['loyalty-first-time-user-legal-modal']: undefined
            });
        });
    };

    return (
        <>
            <StandardModal
                className={classNames(
                    className,
                    styles.modal,
                    styles['modal-loyalty']
                )}
                body={
                    <div className={styles['modal-body']}>
                        <p className={styles.subtitle}>{subtitle}</p>
                        <p
                            className={styles.legal}
                            dangerouslySetInnerHTML={{ __html: legal }}
                        />
                    </div>
                }
                footer={
                    <>
                        <BrandedButton
                            className={styles.cta}
                            onClick={acceptTerms}
                        >
                            {ctaTitle}
                        </BrandedButton>
                        {!emailOptIn && (
                            <div className={styles['email-opt-in']}>
                                <BrandedCheckbox
                                    checkboxProps={{
                                        checked: optInChecked,
                                        onChange: () => {
                                            setOptInChecked(!optInChecked);
                                        }
                                    }}
                                    className={styles['email-opt-in-check']}
                                    svgProps={{
                                        size: '1.5em',
                                        svgId: 'icon-checkmark'
                                    }}
                                />
                                <span>{emailOptInNotice}</span>
                            </div>
                        )}
                    </>
                }
                gaDataLayerConfig={{
                    'Analytics-Action': 'Rewards > Sign Up',
                    'Analytics-Value': 'Existing Account > Sign Up Success',
                    _event: 'rewards',
                    modalName: 'rewards-existing-account'
                }}
                hideClose={hideClose}
                id="loyalty-first-time-user-legal-modal"
                title={<div className={styles['modal-header']}>{title}</div>}
            />
        </>
    );
};

export default LoyaltyFirstTimeUserLegalModal;
