import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import { setCSRFTokenHeader } from '@tb-core/helpers/client/auth';
import Fetch from '@tb-core/helpers/fetch';
import { loyaltyActivateUserUrl } from '@tb-core/next/api/urls';
import { LoyaltyActivateUserResponseProps } from '@tb-core/types/loyalty';

const postLoyaltyActivateUser = async (emailOptIn: boolean) => {
    let res: Response;

    try {
        res = await Fetch({
            body: JSON.stringify({ emailOptIn }),
            headers: setCSRFTokenHeader({
                'Content-Type': 'application/json'
            }),
            host: '',
            method: 'POST',
            url: devProxyResolve(loyaltyActivateUserUrl)
        });
    } catch (e) {
        console.error('Loyalty Activate User API POST request failed!', e);
        return {
            errors: [
                {
                    message: 'Unable to activate user.',
                    reason: 'The server could not be reached.'
                }
            ],
            success: false
        } as LoyaltyActivateUserResponseProps;
    }

    if (res.status !== 202) {
        return {
            errors: [
                {
                    message: 'Unable to activate user.',
                    reason: 'The server responded with an error.'
                }
            ],
            success: false
        } as LoyaltyActivateUserResponseProps;
    }

    return { success: true } as LoyaltyActivateUserResponseProps;
};

export default postLoyaltyActivateUser;
