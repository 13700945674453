import ImageErrorModal from '@tb-core/components/container/image-error-modal';
import LoyaltyFirstTimeUserLegalModal from '@tb-core/components/container/loyalty-modal/legal';
import LoyaltyFirstTimeUserRewardModal from '@tb-core/components/container/loyalty-modal/reward';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

const LoyaltyModals = () => {
    const { loyalty } = usePageContext().content.pageData.topicMeta;
    const user = useUserContext();
    return (
        <>
            {loyalty?.errorModal && (
                <ImageErrorModal id="loyalty-error-modal" />
            )}
            {loyalty?.firstTimeUserLegalModal && (
                <LoyaltyFirstTimeUserLegalModal
                    emailOptIn={user.isLoggedIn ? user.emailOptIn : undefined}
                    hideClose={true}
                    template={loyalty.firstTimeUserLegalModal}
                />
            )}
            {loyalty?.firstTimeUserRewardModal && (
                <LoyaltyFirstTimeUserRewardModal
                    template={loyalty.firstTimeUserRewardModal}
                />
            )}
        </>
    );
};

export default LoyaltyModals;
