import classNames from 'classnames';

import CustomCheckbox, {
    CustomCheckboxProps
} from '@tb-core/components/styled/form-controls/custom-checkbox';
import ThemedColor, { ColorTheme } from '@tb-core/components/themed/color';

import styles from './styles.module.scss';

export interface ThemableCheckboxProps extends CustomCheckboxProps {
    theme: ColorTheme;
}

const ThemableCheckbox = ({
    className,
    theme,
    ...props
}: ThemableCheckboxProps) => (
    <ThemedColor theme={theme}>
        {({ color }) => (
            <>
                <CustomCheckbox
                    {...props}
                    className={classNames(color, styles['checkbox'], className)}
                />
            </>
        )}
    </ThemedColor>
);

export default ThemableCheckbox;
