import classNames from 'classnames';
import { useRef } from 'react';

import LoyaltyDashboardContainer from '@tb-core/components/container/loyalty';
import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import useOnClickOutside from '@tb-core/hooks/use-on-click-outside';
import usePageContext from '@tb-core/hooks/use-page-context';

import styles from '@tb-core/components/regions/aside-loyalty/styles.module.scss';

const LoyaltyAsideRegion = () => {
    const asideRef = useRef<HTMLDivElement>(null);
    const { loyalty } = usePageContext().content.pageData.topicMeta;
    const { activeLoyalty, activateLoyalty } = useLayoutActivityContext();

    // Close aside tray when clicking 'outside' of the loyalty container.
    const updateLayer = () => {
        updateDataLayer({
            'Analytics-Action': 'Loyalty Tray',
            'Analytics-Value': 'Close'
        });
        activateLoyalty(false);
    };

    useOnClickOutside(asideRef, updateLayer);

    return (
        <>
            {loyalty && activeLoyalty === true && (
                <aside
                    className={classNames(styles.tray, {
                        [styles.active]: activeLoyalty === true
                    })}
                >
                    <div className={styles.content} ref={asideRef}>
                        <LoyaltyDashboardContainer
                            activate={activateLoyalty}
                            active={activeLoyalty}
                            loyaltyTemplate={loyalty}
                        />
                    </div>
                    <LazyIconClose
                        className={styles.close}
                        height="1em"
                        onClick={updateLayer}
                        width="1em"
                    />
                </aside>
            )}
        </>
    );
};

export default LoyaltyAsideRegion;
