import classNames from 'classnames';

import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import StandardModal from '@tb-core/components/composites/standard-modal';
import { ModalProps } from '@tb-core/components/container/loyalty-modal';
import AppIcons from '@tb-core/components/styled/app-icons';
import { setCookies } from '@tb-core/helpers/document-cookie';

import styles from './styles.module.scss';

const LoyaltyFirstTimeUserRewardModal = ({
    className,
    template = {}
}: ModalProps) => {
    const {
        title,
        subtitle,
        productImg,
        productImgAlt,
        productImgTitle,
        legal
    } = template;
    const setCookieVal = () => setCookies({ is_first_time_user: 'false' });

    return (
        <>
            <StandardModal
                className={classNames(
                    className,
                    styles.modal,
                    styles['modal-loyalty']
                )}
                body={
                    <>
                        <div className={styles['modal-body']}>
                            <p
                                className={styles.subtitle}
                                dangerouslySetInnerHTML={{
                                    __html: subtitle
                                }}
                            />
                            <ResponsiveImage
                                alt={productImgAlt}
                                className={styles['product-image']}
                                src={productImg}
                            />
                            <p className={styles['subtitle-img']}>
                                {productImgTitle}
                            </p>
                            <AppIcons className={styles['app-icons']} />
                        </div>
                    </>
                }
                footer={
                    <p
                        className={styles.legal}
                        dangerouslySetInnerHTML={{ __html: legal }}
                    />
                }
                gaDataLayerConfig={{
                    'Analytics-Action': 'Rewards > Sign Up',
                    'Analytics-Value': 'New Account > Sign Up Success',
                    _event: 'rewards',
                    modalName: 'rewards-new-account'
                }}
                id="loyalty-first-time-user-reward-modal"
                onClose={setCookieVal}
                title={<div className={styles['modal-header']}>{title}</div>}
            />
        </>
    );
};

export default LoyaltyFirstTimeUserRewardModal;
